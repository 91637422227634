button.btn,
.btn {
  @apply inline-flex transition-colors duration-200 items-center text-center justify-center relative;
}

button.btn-primary,
.btn-primary {
  @apply border border-brand-100 bg-brand-100 text-md text-white hover:border-brand-100 hover:bg-brand-100 disabled:cursor-not-allowed disabled:border-brand-100 disabled:bg-brand-100;
}

button.btn-secondary,
.btn-secondary {
  @apply border border-brand-100 bg-brand-100 text-md text-white hover:border-brand-100 hover:bg-brand-100 disabled:cursor-not-allowed disabled:border-brand-100 disabled:bg-brand-100;
}

button.btn-ternary,
.btn-ternary {
  @apply border border-white bg-white text-md text-brand-100 hover:bg-white disabled:cursor-not-allowed disabled:border-black-400 disabled:bg-black-400;
}

button.btn-outlinePrimary,
.btn-outlinePrimary {
  @apply  border border-brand-100 bg-transparent text-md text-brand-100 hover:border-brand-100 hover:text-brand-100 disabled:cursor-not-allowed disabled:border-black-400 disabled:text-black-400;
  font-weight: normal;
}



button.btn-outlineSecondary,
.btn-outlineSecondary {
  @apply  border border-yellow-100 bg-transparent text-md text-yellow-100 hover:border-yellow-100 hover:text-yellow-100 disabled:cursor-not-allowed disabled:border-black-400 disabled:text-black-400;
  font-weight: normal;
}

button.btn-outlineTernary,
.btn-outlineTernary {
}

button.btn-primaryUnderline,
.btn-primaryUnderline {
  @apply ltr:mr-1 rtl:ml-1 underline text-brand-100;
}

button.btn-basicUnderline,
.btn-basicUnderline {
  @apply ltr:mr-1 rtl:ml-1 underline;
}

button.btn-primaryText,
.btn-primaryText {
  @apply ltr:mr-1 rtl:ml-1 text-brand-100;
}

button.btn-basicText,
.btn-basicText {
  @apply ltr:mr-1 rtl:ml-1;
}

.btn-size-2xs {
  @apply px-1 py-0.5 text-2xs;
}
.btn-size-xs {
  @apply px-2 py-1 text-xs;
}
.btn-size-sm {
  @apply px-2 py-1.5 text-sm;
}
.btn-size-md {
  @apply px-3 py-2 text-md;
}
.btn-size-lg {
  @apply px-4 py-2 text-lg;
}
.btn-size-xl {
  @apply px-5 py-3 text-xl;
}
.btn-size-2xl {
  @apply px-6 py-4 text-2xl;
}

.btn-rounded-none {
  @apply rounded-none;
}
.btn-rounded-sm {
  @apply rounded-sm;
}
.btn-rounded-default {
  @apply rounded;
}
.btn-rounded-md {
  @apply rounded-md;
}
.btn-rounded-lg {
  @apply rounded-lg;
}
.btn-rounded-xl {
  @apply rounded-xl;
}
.btn-rounded-2xl {
  @apply rounded-2xl;
}
.btn-rounded-3xl {
  @apply rounded-3xl;
}
.btn-rounded-full {
  @apply rounded-full;
}

.btn-cases-lower {
  @apply lowercase;
}

.btn-cases-upper {
  @apply uppercase;
}

.btn-cases-normal {
  @apply normal-case;
}